/*
	@@ HOMEPAGE EVENT @@
*/
&.homepage {

	// Reassurances
	.wrap-reassurances {
		& > div {
			display: block;
			padding: 20px;
			margin-top: 20px;
			text-align: justify;

			&:nth-child(0) {
				margin-top: 0;
			}

			h2 {
				display: flex;
				align-items: center;
				font-weight: 500;
				margin-bottom: 15px;
				._transition;

				span {
					width:40px;
					height:40px;
					margin-right:10px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color:@black;

					i {
						color: currentColor;
						font-size: 2rem;
					}
				}
			}

			p {
				color: @gray;
				font-size:1.5rem;
			}
		}
	}

	// Testimonial
	.testimonial {
		margin-top:30px;

		.carousel-cell {
			width:100%;
		}
		blockquote {
			margin: 0;
			background-color:#3e3e3e;
			padding: 10px 20px 10px 20px;
		}
	}

	// Numbers
	.numbers {
		margin-top:30px;
		text-align: center;

		.item {
			background-color: @black;
			margin-top:20px;
			padding:10px 15px 15px;

			&:nth-child(0) {
				margin-top: 0;
			}

			p {
				strong {
					display: block;
					color: @primary;
				}
			}
		}
	}




	// Screen > 767px
	._media-s-up({
		// Reassurances
		.wrap-reassurances {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			& > div {
				margin-top:0;
				flex:0 0 48%;
				margin-bottom:20px;
			}
		}

		// Numbers
		.numbers {
			margin-top:60px;
			display: flex;
			justify-content: center;

			.item {
				width:250px;
				margin:0 20px;
			}
		}
	});

	// Screen > 989px
	._media-m-up({
		.wrap-why-choose-testimonial {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.why-choose {
				flex: 0 0 48%
			}
			.testimonial {
				flex: 0 0 48%;
				margin:0;
			}
			.numbers {
				flex:0 0 100%;
			}
		}
	});

	// Screen > 1199px
	._media-l-up({
		// Reassurances
		.wrap-reassurances {
			& > div {
				text-align: left;
				margin-top:0;
				flex:0 0 24.5%;
				margin-bottom:0px;
			}
		}

		// Numbers
		.numbers {
			margin-top:80px;
		}
	});
}
