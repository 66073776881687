/*
	@@ ELPRO EVENT PAGES @@
*/


/*
	COMMON
*/
.contact-social {
	display: flex;
	justify-content: center;
	margin-top: 10px;

	li {
		a {
			display: block;
			padding:5px 15px;
		}
	}
}

/*
	HOMEPAGE
*/
// Accordeons + Nos Services
.wrap-accordeons-services {

	// Accordeons
	.accordeons {

		// Each accordeon
		.accordeon {
			margin-bottom:30px;

			&.shown {
				& > div {
					display: block;
				}
			}

			& > h2 {
				padding-bottom:10px;

				a {
					&:hover {
						color: @primary;
					}
				}
			}

			& > div {
				padding:15px;
				background-color: @black;
			}
		}
	}

	// Services
	.services {}


    // Screen > 767px
    ._media-s-up({
        // Accordeons
        .accordeons {

            // Each accordeon
            .accordeon {
                border-bottom:1px solid #353535;
                padding:10px 0 0;
                margin-bottom:0;

                & > h2 {
                }

                & > div {
                    display: none;
                }
            }
        }

        // Services
        .services {
        }
    });

    // Screen > 989px
    ._media-m-up({
        display: flex;

        // Accordeons
        .accordeons {
            flex: 1;
            padding-right:40px;
        }

        // Services
        .services {
            flex:0 0 400px;
        }
    });

    // Screen > 1199px
    ._media-l-up({
        // Accordeons
        .accordeons {
            padding-right:60px;
        }

        // Services
        .services {
            flex:0 0 500px;
        }
    });
}


/*
	DEVIS / CONTACT
*/
&.event-quotation {

    .wrap-form-pictures {
        .wrap-form {
            margin-bottom: 60px;
        }

        .wrap-pictures {
            .contact-social a {
                color: @primary;
                display: block;
                padding: 3px 5px;
                margin: 0 5px;
                font-size: 2rem;
            }
        }
    }

    // FORM QUOTATION
    .form-quotation {
        .form-item {

            // ERRORS
            &.error {
                label {
                    color: @red;
                }

                input, select, textarea {
                    background-color: lighten(@red, 47%);
                    color: @red;
                }
            }

            // Checkbox
            &.form-checkbox {
                display: block;
                margin-right: inherit;

                input {
                    margin-right: 10px;
                    top: 3px;
                }

                label {
                    display: inline-block;
                    padding-right: 0;
                }
            }

            // All but not checkbox
            &:not(.form-checkbox) {
                label {
                    display: block;
                    padding: 0 0 7px;
                }
            }

            // General style
            label {
                &.required:before {
                    content: "*";
                }
            }

            input:not([type="checkbox"]) {
                display: block;
                width: 100%;
            }

            textarea {
                height: 250px;
            }
        }
    }


    // Screen > 767px
    ._media-s-up({

        #main-header {
            height: 310px;
            min-height: 310px;
        }

        /*
            DEVIS / CONTACT
        */
        .wrap-form-pictures {
            display: flex;

            .wrap-form {
                margin-bottom: 0px;
                flex: 1;
                margin-right: 40px;
            }

            .wrap-pictures {
                flex:0 0 215px;

                img {
                    width:100%;
                }
            }
        }
    });

    // Screen > 989px
    ._media-m-up({
        /*
            DEVIS / CONTACT
        */
        .wrap-form-pictures {
            .wrap-form {
                margin-right: 60px;
            }

            .wrap-pictures {
                flex:0 0 250px;
            }
        }
        .form-quotation {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .form-item {
                flex:0 0 48%;
            }
            .full-width {
                flex: 0 0 100%;
            }
        }
    });


    // Screen > 1199px
    // Pour faire plaisir à Adrien, faut condenser cette page parce que sur son écran 13 pouces, il ne voit pas la haut du formulaire
    ._media-l-up({

        #main-content {
            & > .section {
                padding: 40px 0;
            }
        }


    });
}
