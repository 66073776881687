/*
	@@ EVENT SECTION @@
*/
body.event {
    @import "common";
    @import "homepage";
    @import "your-event-template";
    @import "activities-list";
    @import "activity-detail";
    @import "housing-detail";
    @import "event-event";


    // Link "Etre recontacté" sur toutes les pages Event
    a.event-contact, a.event-phone {
        border-radius: 0;
        display: flex;
        align-items: center;
        background-color: #ba186d;
        color: white;
        padding: 8px;
        position: fixed;
        bottom: 10px;
        right: 10px;

        &.event-phone {
            bottom: 50px;
        }

        span {
            display: block;
            font-size: 1.3rem;
            font-weight: 700;
        }

        i {
            display: block;
            margin-right: 10px;
            transform: scale(-1, 1);
        }

        ._media-s-up({
            width: 200px;
            height: 55px;
            text-align: center;
            padding: 5px 10px;
            bottom: 20px;
            right: 20px;

            &.event-phone {
                bottom: 90px;
            }


            span {
                font-size: 1.5rem;
            }

            i {
                margin-right: 20px;
                color: #fff;
                font-size: 30pt;
            }
        });
    }
}

