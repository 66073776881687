/*
	@@ COMMON STYLES @@
	- Override default styles elements
	- Sections
	- .intro Texts
	- .body
	- breadcrumb
	- Loadres for Flickity sliders
	- "Le Saviez-vous ?" boxes
	- "Checklists texts" on pages
	- "Discover our others univers" section
	- Jobs List in Univers pages
	- Top Images + Links in detail pages : Type, Activity, Store detail
	- Activities list in Job pages, Ski school detail page
	- Leaflet Maps
	- Instagram in footer
*/

// - Override default styles elements
// FAT H1 OR FAT TOP TEXT : Details pages
.cc-fat {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.1;

    small {
        display: block;
        font-weight: 300;
        font-size: 60%;
    }

    ._media-s-up({
        font-size: 4rem;
    });

    ._media-m-up({
        font-size: 5rem;
    });

    ._media-l-up({
        font-size: 6rem;
    });
}

// Underlined titles
h2, .h2-like {
    &.underlined {
        &:after {
            content: ' ';
            display: block;
            height: 1px;
            width: 150px;
            background-color: @primary;
            margin-top: 20px;
        }

        &.cc-txt-center {
            &:after {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    ._media-s-up({
        &.underlined {
            &:after {

            }
        }
    });
    ._media-m-up({
        &.underlined {
            &:after {
                margin-top: 25px;
            }
        }
    });
}

// links
a {
    ._transition;

    &.cc-white-hover {
        &:hover {
            color: white;
        }
    }

    #main-footer &,
    #main-nav &,
    &.cc-primary-hover {
        &:hover {
            color: @primary;
        }
    }
}

// Buttons/links
a.btn,
button {
    background-color: transparent;
    border-color: @primary;
    text-transform: uppercase;
    color: @primary;
    font-size: 1.4rem;
    font-weight: 400;

    /* hover: default, change border-color */

    &:hover {
        border-color: @primary;
        background-color: @black;
    }

    // Block buttons links
    &.cc-block {
        display: block;
    }

    // White buttons links
    &.cc-white {
        color: white;
        border-color: white;

        &:hover {
            background-color: @black;
        }
    }

    // White button with primary effects on hover
    &.cc-primary-hover {
        &:hover {
            border-color: @primary;
            color: @primary;
        }
    }

    // Screen > 989px
    ._media-m-up({
        font-size: inherit;
    });
}

.backstretch {
    height: 100%;
}

table {
    th, td {
        &.cc-bg-ardoise {
            background-color: @ardoise;
        }

        &.cc-bg-green {
            background-color: #266126
        }

        &.cc-bg-yellow {
            background-color: #a29f31;
        }
    }
}

.cc-green {
    color: #64c749
}

.cc-blue {
    color: #13b4ff;
}

.cc-red {
    color: #b73232;
}


// - Sections
.section {
    padding: 30px 0;

    ._media-s-up({
        padding: 40px 0;
    });

    ._media-l-up({
        padding: 80px 0;

        &.cc-pab-0-l {
            padding-bottom: 0
        }
    });
}


// Intro texts (not 100% width)
.intro {
    padding-top: 30px;
    text-align: justify;

    &.no-pat {
        padding-top: 0
    }

    p {
        padding-bottom: 20px;
    }

    strong {
        font-weight: 400;
    }

    ._media-m-up({
        text-align: center;
        padding-top: 50px;
        font-size: 1.8rem;
    });
}


// - .body
.body {
    text-align: justify;

    h3, h2 {
        color: @primary;
        font-size: 2rem;
        padding-bottom: 20px;
    }

    p {
        padding-bottom: 20px;
        line-height: 1.3
    }

    a {
        color: @primary;
    }

    strong {
        font-weight: 400;
    }

    ul {
        li {
            list-style: none;
            position: relative;
            padding-left: 15px;

            &:before {
                content: ' ';
                display: block;
                position: absolute;
                border-radius: 100%;
                width: 6px;
                height: 6px;
                background-color: @primary;
                top: 9px;
                left: 0px;
            }

            p {
                padding: 0;
            }
        }

        &.iconed {
            li {
                padding: 2px 0 2px 30px;

                &:before {
                    top: 6px;
                    content: '\f00c';
                    font-family: 'Font Awesome 5 Pro';
                    font-weight: 300;
                    -webkit-font-smoothing: antialiased;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    color: @primary;
                    border-radius: 0;
                    width: inherit;
                    height: inherit;
                    background-color: transparent;
                }
            }

            &.icons-check li:before {
                content: '\f00c';
            }

            &.icons-heart li:before {
                content: '\f004';
            }

            &.icons-stars li:before {
                content: '\f005';
            }

            &.icons-right-arrow li:before {
                content: '\f105';
            }
        }
    }

    table {
        border-collapse: collapse;
        width: 100% !important;

        th, td {
            vertical-align: top;
        }

        tr:hover {
            td {
                background-color: #121212;
            }
        }
    }


    ._media-s-up({
        h2 {
            font-size: 2.2rem;
        }

        h3 {
            font-size: 2.2rem;
        }

    });

    ._media-m-up({
        text-align: inherit;

        h2 {
            font-size: 3rem;
        }

        h3 {
            font-size: 2.5rem;
        }
    });
}


// - Breadcrumb
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
        color: @gray;

        a {
            color: @gray;
            display: block;
            padding: 0px 10px;

            span {
                padding-right: 20px;
            }

            &:after {
                display: inline-block;
                content: "\f105";
                font-family: 'Font Awesome 5 Pro';
                font-weight: 300;
            }
        }

        &:last-of-type {
            a {
                span {
                    padding-right: 0;
                }

                &:after {
                    content: '';
                }
            }
        }
    }
}


// - Loaders for Flickity sliders
.wrap-slider {
    position: relative;

    .back-link {
        position: absolute;
        bottom: 20px;
        left: 20px;

        a {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}


// - "Le Saviez-vous ?"
.didyouknow {
    background-color: @black;
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;

    i {
        color: @primary;
        font-size: 4rem;
    }

    & > div {
        .title {
            font-size: 2.5rem;
            padding: 15px 0;
        }

        .body {
            text-align: justify;

            a {
                color: @primary;
            }
        }
    }

    // Screen > 767px
    ._media-s-up({
        text-align: left;
        display: flex;
        align-items: center;
        padding: 40px;
        margin-bottom: 40px;

        i {
            font-size: 6rem;
        }

        & > div {
            padding-left: 40px;

            .title {
                font-size: 3rem;
                padding-top: 0;
            }
        }
    });

    // Screen > 989
    ._media-m-up({
        & > div {
            font-size: 1.8rem;

            .title {
                font-size: 3.6rem;
            }
        }
    });

    ._media-l-up({
        margin-bottom: 80px;
    });
}


// - "Checklists texts" on pages
.checklists {

    .checklist {
        margin-top: 30px;

        &:nth-child(1) {
            margin-top: 0;
        }

        .title {
            font-size: 2rem;
            margin-bottom: 20px;

            &:after {
                content: ' ';
                display: block;
                height: 1px;
                width: 150px;
                background-color: @primary;
                margin-top: 10px;
            }
        }


        // Specific for Monitors
        .monitors-list {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            li {
                padding: 0 10px 30px;

                &:before {
                    content: '';
                    display: none;
                }

                img {
                    display: block;
                    margin: 0 auto 15px;
                }

                p {
                    line-height: 1.2;
                    text-transform: uppercase;

                    strong {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    // Screen > 767px
    ._media-s-up({
        .checklist {
            .title {
                font-size: 2.6rem;
            }
        }
    });


    // Screen > 989px
    ._media-m-up({
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .checklist {
            margin: 0 0 50px;
            flex: 0 0 47%;

            .title {
                line-height: 1.2;

                &:after {
                    margin-top: 15px;
                }
            }
        }
    });


    // Screen > 1250px
    ._media-l-up({
        .checklist {
            .title {
                font-size: 3rem;
                margin-bottom: 25px;
            }
        }
    });
}

// - "Discover our others univers" section
.discover-pushes {
    padding-bottom: 0;

    .title3, .title4 {
        display: block;
    }

    header .title3 {
        font-size: 2.2rem;
    }

    .left {
        .wrap-push {

            &:hover {
                .push-texts {
                    background-color: #121212;
                }

                & > .backstretch {
                    &:after {
                        border-color: transparent transparent #121212 transparent;
                    }
                }
            }


            & > .backstretch {
                height: 250px;
                position: relative;

                &:after {
                    content: ' ';
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 60px 40px 60px;
                    border-color: transparent transparent @black transparent;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    margin-left: -60px;
                    z-index: 1;
                    ._transition(0.3s, border-color);
                }
            }

            .push-texts {
                background-color: @black;
                padding: 20px 20px;
                text-align: center;
                ._transition(0.3s, background-color);

                .title4 {
                    font-size: 2rem;
                    line-height: 1.2;
                }

                div {
                    line-height: 1.2;
                }

                p a {
                    margin-left: 7px;

                    &:nth-child(1) {
                        margin-left: 0;
                    }

                }
            }
        }
    }

    // Right Big block
    .right {
        height: 350px;
        position: relative;

        .cc-inside {
            position: relative;
            z-index: 2;
            width: 100%;
            padding: 0px 20px;
        }

        .title3 {
            font-size: 2rem;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 340px;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 30%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000',GradientType=0); /* IE6-9 */
            z-index: 1;
        }
    }


    // Screen > 767px
    ._media-s-up({

        header .title3 {
            font-size: 2.6rem;
        }

        .left {
            .wrap-push {
                display: flex;


                &:hover {
                    & > .backstretch {
                        &:after {
                            border-color: transparent #121212 transparent transparent;
                        }
                    }
                }

                &.item-inverse:hover {
                    & > .backstretch {
                        &:after {
                            border-color: transparent transparent transparent #121212;
                        }
                    }
                }

                & > .backstretch {
                    flex: 0 0 50%;
                    height: 300px;

                    &:after {
                        border-width: 60px 40px 60px 0;
                        border-color: transparent @black transparent transparent;
                        margin-left: inherit;
                        left: inherit;
                        right: 0;
                        bottom: inherit;
                        top: 50%;
                        margin-top: -60px;
                    }

                }

                .push-texts {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    text-align: left;
                    padding: 0 20px;

                    .title4 {
                        font-size: 2.4rem;
                    }
                }

                // Inverse Item
                &.item-inverse {
                    & > .backstretch {
                        &:after {
                            border-width: 60px 0 60px 40px;
                            border-color: transparent transparent transparent #000000;
                            right: inherit;
                            left: 0;
                        }
                    }

                    .push-texts {
                        order: -1;
                    }

                }
            }
        }

        // Right Big block
        .right {
            height: 400px;

            .title3 {
                font-size: 2.4rem;
            }
        }
    });


    // Screen > 989px
    ._media-m-up({
        header .title3 {
            font-size: 3.6rem;
        }
    });


    // Screen > 1249px;
    ._media-l-up({
        header .title3 {
            font-size: 3.6rem;
        }

        .wrap-discover-pushes {
            display: flex;

            .left,
            .right {
                flex: 0 0 50.00%;
            }


            // Right Big block
            .right {
                padding: 30px 0;
                height: 600px;
            }
        }
    });
}


// - Jobs List in Univers pages (Outddor winter, Outdoor Summer pages)
.wrap-activitieslist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    // Each Job item
    .activitieslist-item {
        min-width: 280px;
        max-width: 400px;
        flex: 0 0 40%;
        margin: 0 10px 30px;
        display: flex;
        flex-direction: column;

        &:hover {
            .activitieslist-mainimage {
                opacity: 1;

                img {
                    ._scale(1.05);
                }
            }

            h2 {
                color: @primary;
            }

            .activitieslist-summary {
                color: white;
            }
        }

        // Main image
        .activitieslist-mainimage {
            display: block;
            overflow: hidden;
            opacity: 0.85;
            ._transition;

            img {
                display: block;
                ._transition(1s);
            }
        }

        h2 {
            padding: 10px 20px;
            ._transition;
        }

        // Summary
        .activitieslist-summary {
            padding: 0 20px 20px;
            color: @gray;
            ._transition(0.2s, color);

        }

        // Listing activities
        .activitieslist-activities {
            color: @primary;
            margin-bottom: 20px;

            &:before {
                display: block;
                content: ' ';
                width: 60%;
                height: 1px;
                background-color: @primary;
                margin: 0px auto 10px;
            }

            li {
                text-align: center;

                a {
                    color: @primary;
                    ._transition;

                    &:hover {
                        color: white;
                    }
                }
            }
        }

        .cc-block {
            margin-top: auto;
        }
    }

    ._media-m-up({
        // Each Job item
        .activitieslist-item {
            flex: 0 0 290px;

            // Listing activities
            .activitieslist-activities {
                margin-top: auto;
                min-height: 140px;
            }


            .cc-block {
                margin-top: inherit;;
            }
        }
    });


    // Screen > 1250px
    ._media-l-up({
        justify-content: center;
        // Each Job item
        .activitieslist-item {
            flex: 1;
            min-width: inherit;

            // Listing activities
            .activitieslist-activities {
                margin-top: auto;
                min-height: 140px;
            }


            .cc-block {
                margin-top: inherit;;
            }
        }


    });
}


// - Top Images + Links in detail pages : Type, Activity, store detail
.wrap-top-image-links {

    // Carousel
    .main-carousel {
        height: 350px;
        opacity: 0;
        ._transition(0.4s, opacity);

        &.shown {
            opacity: 1;
        }

        .carousel-cell {
            width: 100%;
            height: 100%;
            position: relative;
        }
    }

    .links {
        padding: 30px;

        button,
        a {
            width: 100%;
            display: block;
            margin: 15px 0 0;
        }

        button {
            margin: 0;
        }

        nav {
            position: fixed;
            height: 100vh;
            z-index: 10;
            background-color: @black;
            top: 0;
            right: -100%;
            width: 100%;
            bottom: 0;
            overflow-y: auto;
            ._transition(0.35s, right, cubic-bezier(1, 0, 0, 1));

            & > ul {
                padding: 0 20px;

                & > li {
                    text-align: center;
                    padding: 5px;

                    &:nth-child(1) {
                        padding: 0;
                    }

                    p {
                        margin-top: 25px;
                        font-size: 2rem;
                        font-weight: 600;
                    }

                    // Close button
                    button {
                        padding: 10px;
                        border: 0;
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    // Screen > 767
    ._media-s-up({
        // Carousel
        .main-carousel {
            height: 450px;
        }

    });

    // Screen > 989px
    ._media-m-up({
        display: flex;

        .wrap-slider {
            flex: 1;
            height: 100%;

            .main-carousel {
                height: 620px;
            }
        }

        .links {
            flex: 0 0 350px;

            button {
                display: none;
            }

            nav {
                position: static;
                height: inherit;
                z-index: 10;
                overflow-y: auto;
                transition: none;

                & > ul {
                    max-height: 560px;
                    overflow-y: auto;
                }
            }
        }
    });
}


// - Activities list in Job pages, Ski school detail page
.wrap-actitivies-list {
    display: flex;
    flex-wrap: wrap;

    // Each activity item
    .activity-item {
        margin: 30px auto 0;
        flex: 0 0 100%;
        max-width: 400px;

        &:nth-child(1) {
            margin-top: 0;
        }

        & > a {
            display: block;
        }

        .activity-infos {
            padding: 20px;

            h2 {
                color: @primary;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                position: relative;
                display: flex;
                justify-content: center;

                span {
                    padding: 0 10px;
                    background-color: @black;
                    line-height: 1.1;
                    position: relative;
                    z-index: 1;
                    max-width: 80%;
                }

                &:before {
                    content: ' ';
                    height: 1px;
                    width: 100%;
                    background-color: @primary;
                    position: absolute;
                    top: 11px;
                    left: 0;
                    right: 0;
                }
            }

            .activity-summary {
                text-transform: uppercase;
                padding-top: 15px;
            }

            .activity-infoslist {
                padding-top: 15px;
                color: @gray;

                li {
                    margin-top: 10px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    span {
                        text-align: right;
                        line-height: 1.2;
                        flex: 1;
                    }

                    i {
                        color: @primary;
                        font-size: 3rem;
                        display: block;
                        width: 55px;
                        flex: 0 0 55px;
                        text-align: center;
                    }
                }
            }
        }
    }


    // Screen > 767px
    ._media-s-up({
        justify-content: space-around;

        .activity-item {
            flex: 0 0 45%;
            margin: 0 0 40px;

            .activity-img {
                overflow: hidden;

                img {
                    display: block;
                    max-width: inherit;
                    height: 100%;
                }
            }
        }
    });


    // Screen > 898px
    ._media-m-up({
        .activity-item {
            .activity-infos {
                h2 {
                    font-size: 2.6rem;

                    &:before {
                        top: 14px;
                    }
                }
            }
        }
    });


    // Screen > 1250px
    ._media-l-up({
        justify-content: space-between;

        .activity-item {
            flex: 0 0 48%;
            max-width: 48%;
            width: 48%;
            max-width: inherit;

            & > a {
                position: relative;
                padding-left: 40%;
                height: 100%;
                min-height: 380px;
                ._transition;

                &:hover {
                    .activity-img {
                        clip-path: polygon(0% 0%, 65% 0%, 90% 50%, 65% 100%, 0% 100%);
                    }
                }
            }

            .activity-img {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 40%;
                z-index: 1;
                clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
                ._transition(0.2s, clip-path);
            }

            .activity-infos {
                position: relative;
                z-index: 2;
                padding: 20px 40px 20px 0;
                height: 100%;

                h2 {
                    font-size: 3rem;

                    &:before {
                        top: 18px;
                    }
                }
            }
        }
    });
}


// - Leaflet maps
.leaflet-maps {
    height: 300px;

    a.btn:hover {
        background-color: @primary;
        color: white;
    }

    ._media-m-up({
        height: 480px;
    });
}


// - Instagram in Footer
.insta {
    margin-bottom: 35px;

    span.title4 {
        display: block;
        text-align: center;
        padding-bottom: 20px;
        font-weight: 500;
    }

    ._media-s-up({
        max-width: 650px;
        margin: 0 auto 35px;
    });

    ._media-l-up({
        span.title4 {
            font-size: 1.8rem;
            border-bottom: 1px solid #484848;
            margin: 0 20px 10px;
            padding-bottom: 10px;
        }
    });
}
