// DETAIL PAGE : Type page, skischool page, activity page, etc...
.detail-page {


    // Main image at the top : skischool detail page;
    .skishool-mainimg {
        height: 300px;
    }

    // Tabs links
    #wrap-tabs {
        max-width: 100vw;
        position: relative;
        margin-bottom:30px;

        // Show right arrow to slide on smartphone
        .tabs-arrow {
            position: absolute;
            display: flex;
            right: 0px;
            top:0;
            bottom:0;
            width: 32px;
            line-height: 1.6rem;
            justify-content: center;
            align-items: center;
            background: rgba(236,0,137,1);

            i {
                animation-duration: 1s;
                animation-fill-mode: both;
                animation-iteration-count: infinite;
                animation-name: shake;
            }

            &.closed {
                display: none;
            }
        }

        #wrap-scroll-tabs {
            width: 100%;
            overflow-y: hidden;
            overflow-x: auto;
            display: flex;
            align-items: stretch;
            white-space: nowrap;
            user-select: none;
        }

        a {
            display: inline-block;
            background-color: @black;
            line-height: 3.2rem;
            padding: 0 10px;
            margin-left: 20px;
            max-width: 240px;

            &:nth-child(1) {
                margin-left: 0px;
            }

            &.active,
            &:hover {
                background-color: @primary;
            }
        }
    }

    // Tabs content
    .wrap-content {
        .item {
            display: none;

            &.active {
                display: block;
                animation-duration: 1s;
                animation-fill-mode: both;
                animation-duration: 0.5s;
                animation-name: fadeInLeft;
            }

            h2, .h2-like {
                margin-bottom:30px;

                &:after {
                    margin-top:10px;
                }
            }
        }

        // Specific for "Description detaillée" in Ski School Activity detail page
        .activity-content {
            .left {
                padding-bottom: 50px;

                // Extend monitors list styles
                .monitors ul:extend(.checklists .checklist .monitors-list all) {}
            }
            .right {
                .activity-infoslist {
                    color: @gray;

                    li {
                        margin-top:10px;
                        display: flex;
                        align-items: center;

                        &:nth-child(1) {
                            margin-top: 0;
                        }

                        span {
                            text-align: left;
                            line-height: 1.2;
                            flex: 1;
                        }

                        i {
                            color: @primary;
                            font-size: 3rem;
                            display: block;
                            width: 55px;
                            flex: 0 0 55px;
                            text-align: center;
                        }
                    }
                }

                img {
                    display: block;
                    margin: 30px auto 0;
                }
            }
        }

        // Specific for Accordeons (Shop detail : brands, services)
        .global-accordeon-wrapper {
            .left {
                display: none;
            }
            .right {
                margin-top:30px;

                .item-detail {
                    margin-top: 30px;

                    &:nth-child(1) {
                        margin-top: 0;
                    }

                    .icon {
                        margin:10px auto;
                        background-color: @primary;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;

                        i {
                            font-size: 2rem;
                        }
                    }

                    h3 {
                        font-size: 2.2rem
                    }

                    .brand-logo,
                    .imgs {
                        img {
                            display: block;
                            margin:0 auto 20px;
                            max-width: 100%;
                        }
                    }

                    // Specific for "Products" tabs
                    &.product-tab {
                        border-top: 1px solid @primary;
                        margin-top: 30px;
                        padding-top: 30px;

                        &:nth-child(1) {
                            margin:0;
                            border:0;
                            padding: 0;
                        }
                    }

                    .wrap-brands {
                        margin-top: 30px;

                        .brand-item {
                            margin-top: 30px;

                            &:nth-child(1) {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Specific for Opening hours and Localisation (Shop detail)
        .opening-hours {
            .title {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            td {
                padding: 8px 0;
            }
        }
    }


    // Screen > 767px
    ._media-s-up({

        // Main image at the top : skischool detail page;
        .skishool-mainimg {
            height: 450px;
        }

        // Tabs links
        #wrap-tabs {
            margin-bottom:40px;
        }

        // Tabs content
        .wrap-content {
            .item {
                h2, .h2-like  {
                    margin-bottom:40px;
                }
            }

            // Specific for Accordeons (Shop detail : brands, services)
            .global-accordeon-wrapper {
                .right {
                    .item-detail {
                        .imgs {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;

                            img {
                                margin: inherit;
                                max-width:48%;
                            }
                        }

                        .wrap-brands {
                            // display:flex;
                            // flex-wrap: wrap;
                            // justify-content: space-between;

                            & > h4 {
                                // flex: 0 0 100%;
                            }

                            .brand-item {
                                // margin-top: 30px;
                                // flex:0 0 47%;
                            }
                        }
                    }
                }
            }
        }
    });

    // Screen > 989px
    ._media-m-up({

        // Tabs links
        #wrap-tabs {
            .tabs-arrow {
                display:none;
            }

            #scroll-tabs {
                width: 100%;
                display: flex;
                align-items: center;
            }

            a {
                flex: 1;
                margin-left:0;
                font-size: 2rem;
                line-height: 5rem;
                text-align: center;
                position: relative;

                &:after {
                    content: ' ';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 45%;
                    margin-left: -7px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 7px 8px 7px;
                    border-color: transparent transparent @black transparent;
                    ._transition(0.2s, left);
                }

                &.active,
                &:hover {
                    &:after {
                        left: 50%;
                    }
                }
            }
        }

        // Tabs content
        .wrap-content {
            // Specific for "Description detaillée"
            .wrap-activity-content {
                display: flex;
            }
            .activity-content {
                .left {
                    flex: 1;
                    padding-bottom: 0px;
                    padding-right:50px;
                }
                .right {
                    flex:0 0 350px;
                }
            }

            // Specific for Accordeons (Shop detail : brands, services)
            .global-accordeon-wrapper {
                display: flex;
                margin-top:40px;

                .left {
                    flex:0 0 250px;
                    display: block;

                    a {
                        display: flex;
                        align-items: center;
                        padding: 20px;
                        background-color: #121212;
                        ._transition(0.2s, background-color);

                        .icon {
                            flex:0 0 50px;
                            background-color: @black;
                            width: 50px;
                            height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                            margin-right: 20px;
                            ._transition;

                            i {
                                font-size: 2.5rem;
                            }
                        }

                        p {
                            font-size: 2rem;
                        }

                        &:hover,
                        &.active {
                            background-color: @black;

                            .icon {
                                background-color: @primary;
                            }
                        }
                    }
                }
                .right {
                    margin-top: 0;
                    flex: 1;

                    .item-detail {
                        display: none;
                        margin-top: 0px;

                        &.active {
                            display: block;
                            animation-duration: 1s;
                            animation-fill-mode: both;
                            animation-duration: 0.5s;
                            animation-name: fadeInLeft;
                        }

                        .icon {
                            width: 70px;
                            height: 70px;

                            i {
                                font-size: 3.6rem;
                            }
                        }

                        h3 {
                            font-size: 3.6rem
                        }

                        .imgs {
                            img {
                                max-width: 48%;
                            }
                        }


                        // Specific for "Products" tabs
                        &.product-tab {
                            margin:0;
                            border:0;
                            padding: 0;
                        }
                    }
                }
            }


            // Specific for Opening hours and Localisation (Shop detail)
            .wrap-opening-hours {
                display: flex;
            }
            // Opening hours
            .opening-hours {
                flex: 1;

                &.opening-winter {
                    margin-right: 15px;
                }
                &.opening-summer {
                    margin-left: 15px;
                }
            }


            // Specific for detail shop page : Display Shop infos in "Description" tab
            .wrap-body-infos {
                display: flex;

                .shop-infos {
                    order: 2;
                    flex:0 0 400px;
                    margin-left: 40px;
                    align-self: flex-start;
                }
            }
        }
    });

    // Screen > 1250px
    ._media-l-up({

        // Main image at the top : skischool detail page;
        .skishool-mainimg {
            height: 620px;
        }

        // Tabs content
        .wrap-content {
            // Specific for "Description detaillée"
            .activity-content {
                .left {
                    padding-right:60px;
                }
                .right {
                    flex:0 0 560px;
                }
            }

            // Specific for Accordeons (Shop detail : brands, services)
            .global-accordeon-wrapper {
                .left {
                    flex:0 0 300px;
                }
            }
        }
    });
}
