// HOMEPAGE STYLES
.homepage {

	// Oblic Presentation : Outdoor Winter, Outdoor Summer;
	.oblic {
		display: flex;
		flex-wrap: wrap;

		// Texts
		.oblic-texts {
			flex:0 0 100%;
			order:2;
			padding:20px;
			text-align: justify;

			a.btn {
				margin:10px;
			}

			.outdoor-text {
				max-width:600px;
				margin-left:auto;
				margin-right: auto;
			}
		}

		// Illustration
		.oblic-img {
			flex:0 0 100%;
			height: 200px
		}

		&.img-left {
			.oblic-texts {
				order:-2;
			}
		}
	}

	// Seminars push
	.home-seminars {
		height: 200px;
		position: relative;
		margin:1px 0;

		.cc-inside {
			position: relative;
			z-index:2;
		}

		h3 {
			font-size: 2rem;
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 200px;
			background: linear-gradient(to bottom, rgba(0,0,0,0.65) 30%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
			z-index:1;
		}
	}

	// Shops Texts + Carousel
	.home-shops {
		padding-bottom: 0;

		h3 {
			font-size:2rem;

			small {
				display: block;
				font-size: 1.8rem;
			}
		}

		// Carousel
		.main-carousel {
			margin-top:30px;
			height: 350px;
			opacity: 0;
			._transition(0.4s, opacity);

			&.shown {
				opacity: 1;
			}

			.carousel-cell {
				width: 100%;
				height: 100%;
				position: relative;

				.overlay {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					height: 400px;
					background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
					z-index:1;
				}
			}

			.wrap-shop-infos {
				position: relative;
				z-index: 2;
				height: 100%;
				padding:20px;
				font-weight:400;

				// Shop infos, name, address, etc...
				.shop-infos {
					.shop-city {
						text-transform: uppercase;
						font-weight: 600;
						font-size: 2rem;
						padding-bottom: 5px;
					}

					div {
						border-left:4px solid white;
						padding: 5px 0px 5px 10px;

						h4 {
							font-weight: 400;
							text-transform: uppercase;
							font-size:1.6rem;
						}
					}
				}

				// Links : See detail + Skimium rental link;
				.shop-links {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: rgba(0,0,0,0.6);
					padding:10px 20px;
					text-align: center;

					.first-item {
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	// Screen > 359px
	._media-xs-up({
		// Oblic Presentation : Outdoor Winter, Outdoor Summer;
		.oblic {
			.oblic-img {
				height:350px;
			}
		}

		// Seminars push
		.home-seminars {
			height: 350px;
		}
	});

	// Screen > 767px
	._media-s-up({
		// Oblic Presentation : Outdoor Winter, Outdoor Summer;
		.oblic {
			.oblic-texts {
				padding:30px 20px;
			}
		}

		// Shops Texts + Carousel
		.home-shops {
			h3 {
				font-size:2rem;

				small {
					display: block;
					font-size: 1.8rem;
				}
			}

			// Carousel
			.main-carousel {
				margin-top:40px;
				height: 450px;

				.wrap-shop-infos {
					padding:20px 70px;

					// Shop infos, name, address, etc...
					.shop-infos {
						.shop-city {
							font-size: 3.6rem;
							padding-bottom: 10px;
						}

						div {
							border-left:5px solid white;
							padding: 5px 0px 5px 10px;

							h4 {
								font-weight: 400;
								text-transform: uppercase;
								font-size:1.6rem;
							}
						}
					}

					// Links : See detail + Skimium rental link;
					.shop-links {
						padding:20px;
						display:flex;
						justify-content: center;
						align-items: center;

						p {
							margin:0 20px;

							&.first-item {
								margin-bottom: 0px;
							}
						}
					}
				}
			}
		}
	});

	// Screen > 989px
	._media-m-up({
		// Oblic Presentation : Outdoor Winter, Outdoor Summer;
		.oblic {
			overflow:hidden;

			.oblic-texts {
				flex:0 0 50%;
				order:-2;
				padding:50px;

				a.btn {
					
					margin-left: 0;

					&:nth-child(1) {
						margin-right:20px;
					}
				}
			}

			.oblic-img {
				flex: 0 0 50%;
				height: inherit;
				position: relative;

				.backstretch {
					z-index: 1;
				}

				&:before {
					display: block;
					content: ' ';
					position: absolute;
					top:0;
					bottom:0;
					left:0;
					z-index: 2;
					width:200px;
					border-style: solid;
					border-width: 1000px 0 0 200px;
					border-color: transparent transparent transparent @black;
				}
			}

			&.img-left {
				.oblic-texts {
					order:2;
				}

				.oblic-img {
					&:before {
						left:inherit;
						right:0;
						border-width: 0px 0 1000px 200px;
						border-color: transparent transparent @black transparent;
					}
				}
			}
		}

		// Seminars push
		.home-seminars {
			height: 500px;

			h3 {
				font-size: 3rem;
			}
		}

		// Shops Texts + Carousel
		.home-shops {
			h3 {
				font-size:3rem;

				small {
					font-size: 2rem;
				}
			}

			// Carousel
			.main-carousel {
				height: 550px;

				.wrap-shop-infos {
					padding:60px 90px;

					// Shop infos, name, address, etc...
					.shop-infos {
						font-size: 1.8rem;

						.shop-city {
							font-size: 3.6rem;
						}

						div {
							h4 {
								font-size:1.8rem;
							}
						}
					}
				}
			}
		}
	});

	// Screen > 1250px
	._media-l-up({
		// Oblic Presentation : Outdoor Winter, Outdoor Summer;
		.oblic {
			.oblic-texts {
				padding:0 80px;
				min-height:650px;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				align-content: center;
				justify-content: center;				
			}

			.oblic-img {
			}
		}

		// Seminars push
		.home-seminars {
			height: 650px;

			h3 {
				font-size: 4.8rem;
			}

			.overlay {
				height: 400px;
			}
		}

		// Shops Texts + Carousel
		.home-shops {
			h3 {
				font-size: 3.6rem;

				small {
					font-size: 2.4rem;
				}
			}

			// Carousel
			.main-carousel {
				margin-top:60px;
				height:700px;

				.wrap-shop-infos {
					overflow: hidden;

					// Shown slide : anime infos and links
					&.shown {
						.shop-infos {
							._transition(0.4s, all);
							left:0;
							opacity:1;
						}

						.shop-links {
							._transition;
							bottom:0;
						}
					}
				
					.shop-links {
						bottom:-100px;
					}

					.shop-infos {
						position: relative;
						opacity:0;
						left:50px;
					}
				}
			}
		}
	});
}