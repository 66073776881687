/* ********* */
/** COOKIES **/
/* ********* */

/* ********************* */
.event-phone {
    position: fixed;
    background: #ba186d;
    border-radius: 100px;
    width: 60px;
    height: 60px;
    text-align: center;
    padding: 10px 0;
    z-index: 999;
    right: 30px;
    bottom: 100px;

    @media screen and (min-width: 560px) {
        right: 60px;
        bottom: 130px;
    }

    span {
        display: none
    }

    i {
        font-size: 30pt;
        color: #fff;
    }
}
