/*
	@@ ACTIVITY DETAIL PAGE @@
*/
&.detail-page {

	.wrap-tabs-content {

		// Infos with icons
		.wrap-reassurances {
			margin:0 auto 30px;
			max-width: 500px;

            img {
                display: block;
                margin: 30px auto 0;
            }
			.activity-infoslist {
				color: @gray;
				background-color: @black;
				padding: 20px;


				li {
					margin-top:15px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					&:nth-child(1) {
						margin-top: 0;
					}

					em {
						font-style: normal;
						color: white;
						font-size: 1.5rem;
						font-weight: 500
					}

					a {
						color: @primary;
					}

					// Specific for rates caracteristics
					.grade {
						display: flex;

						i {
							color: @primary;
							font-size: 1.3rem;
                            opacity: 0;

                            &.fal {
                                color:darken(@primary, 30%);
                            }

                            &[data-loaded="true"] {
                                animation-name: showLazyRate;
                                animation-duration: 0.7s;
                                animation-fill-mode: both;

                                // Second star
                                & + [data-loaded="true"] {
                                    animation-delay:0.1s;

                                    // Third star
                                    & + [data-loaded="true"] {
                                        animation-delay:0.2s;

                                        // Fourth star
                                        & + [data-loaded="true"] {
                                            animation-delay:0.3s;

                                            // Fifth star
                                            & + [data-loaded="true"] {
                                                animation-delay:0.4s;
                                            }
                                        }
                                    }
                                }
                            }
						}
					}
				}
			}
		}
	}


	// Screen > 767px
	._media-s-up({
		#main-header {
			min-height: inherit;
			height: inherit;
		}


		.wrap-tabs-content {
			display: flex;
			flex-wrap: wrap;

			.wrap-reassurances {
				order: 2;
				flex: 0 0 250px;
				margin-bottom: 0;
			}

			#wrap-tabs {
				flex:0 0 100%;
			}

			.wrap-content {
				flex: 1;
				padding-right: 40px;
			}
		}
	});

	// Screen > 989px
	._media-m-up({

		.wrap-tabs-content {

			.wrap-reassurances {
				flex: 0 0 350px;
			}
		}
	});
}

@keyframes showLazyRate {
  0% {
    opacity: 0;
    ._scale(0.5)
  }
  50% {
    ._scale(1.4);
  }
  100% {
    ._scale(1);
    opacity: 1;
  }
}
