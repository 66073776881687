/* ********* */
/** COOKIES **/
/* ********* */

/* ********************* */
#cookies {
    display: none;
    align-items: center;
    flex-wrap:wrap;
    position: fixed;
    bottom : 0px;
    left: 0px;
    z-index: 99999;
    width: 100%;
    color: #333333;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    padding: 20px;
    font-size:1.8rem;

    &.shown {
        display: flex;
    }

    p {
        margin-bottom:0;
        flex: 1 0 100%;
        padding-bottom: 30px;

        i {
            margin-right: 5px;
            color: @primary;
            font-size: 120%;
            vertical-align: middle;
        }

        a {
            color: @primary;
            border-bottom: 1px solid currentColor;
        }
    }

    a.css-accept-cookie {
        flex:1;
    }

    ._media-s-up({
        padding: 15px 20px;
        font-size: 1.5rem;

        p {
            flex:1;
            padding-bottom: 0;
            padding-right:30px;
        }

        a.css-accept-cookie {
            flex: 0 0 200px;
        }

    });
}
