// SHOPS HOMEPAGE
.shops-list {

	.wrap-skishop-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	// Each Shop item
	.shop-item {
		flex:0 0 300px;
		margin:0 10px 30px;
		width:300px;

		// Backstretch
		& > a {
			position: relative;
			display: block;
			height:300px;
			width:100%;
			overflow: hidden;

			.overlay {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom:0;
				background-color: rgba(0,0,0,0.5);
				z-index:1;
			}

			.shop-info {
				position: relative;
				z-index: 2;
				height:100%;
				display: flex;
				flex-direction: column;

				p {
					margin-top:auto;
				}
			}

			h2 {
				line-height: 1.2;

				strong {
					font-weight:700;
					text-transform: uppercase;
				}
			}
		}
	}


	// Screen > 359px
	._media-xs-up({
	});

	// Screen > 767px
	._media-s-up({
	});

	// Screen > 989px
	._media-m-up({
		

		// Each Shop item
		.shop-item {
			flex:0 0 24%;
			width:24%;
			margin:0 3px 30px;

			// Backstretch
			& > a {
				&:hover {
					.backstretch img {
						._scale(1.05);
					}
				}

				.backstretch img {
					._transition(1s, transform)
				}

				.overlay {
					._transition;
				}

				h2 {
					font-size: 2.2rem;
				}
			}
		}
	});

	// Screen > 1250px
	._media-l-up({

		// Each Shop item
		.shop-item {			
			// Backstretch
			& > a {
				h2 {
					font-size: 3rem;
				}
			}
		}
	});
}