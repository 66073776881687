/*
	@@ ACTIVITIES HOMEPAGE PAGE @@
*/
&.activities-list {
	@media screen and (min-width:450px) {

		// Activities list
		.wrap-activities {
			display: flex;
			flex-wrap: wrap;
		}
	}

	._media-s-up({
		// Specific for Housings list
		.wrap-housings {
			.event-activity-item {
				margin:30px 0 0;

				a {
					position: relative;

					&:hover {
						h3 {
							color:white;
						}
					}

					h3 {
						position: absolute;
						background-color: rgba(255,255,255,0.5);
						padding: 20px;
						left: 0;
						right: 0;
						text-shadow: 1px 1px 2px black;
						bottom: 0%;
						font-size: 3rem;
					}
				}
			}
		}
	});


	._media-m-up({
		// Specific for Housings list
		.wrap-housings {
			.event-activity-item {
				margin:60px 0 0;
			}
		}
	});
}
