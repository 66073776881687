// MAIN HEADER
#main-header {
    .cc-inside {
        padding: 20px;
        position: relative;
        z-index: 2;
    }

    // TOP HEADER : logo, langs, menus...
    .wrap-top-header {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        // Logo
        .logo {
            flex:0 0 35%;
            max-width:100px;
            width:35%;
            margin-right:15px;
            position: relative;
            z-index:2;

            a {
                display: block;
            }
        }

        // FR/EN
        .lang-switcher {

            li.active {
                a {
                    color: @primary;
                }
            }
            li:nth-child(1) {
                border-bottom:1px solid white;

                a {
                    display: block;
                    padding:3px 0;
                }
            }
        }

        // Button "Menu"
        button {
            position: relative;
            border-radius: 5px;
            padding-left: 35px;
            margin-left: auto;
            font-size: 1.8rem;

            i {
                position: absolute;
                top: 7px;
                left: 15px;
            }

            &:hover {
                background-color: rgba(0,0,0,0.5);
            }
        }

        .wrap-contact-infos {
            flex:0 0 100%;
            order:2;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            z-index: 1;
            bottom: 25px;

            a {
                padding:5px 8px;
            }

            span {
                display: none;
            }
        }
    }

    // Specific for Homepage
    .homepage & {
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: black;
            opacity: 0.4;
            z-index:1
        }

        // Boutons Métiers
        .wrap-titles-link {
            position: relative;
            z-index: 2;
            padding:0px 0px 50px;

            h1 {
                font-size: 5rem;

                small {
                    display: block;
                    font-size: 2rem;
                }
            }
            p {
                a {
                    padding: 7px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 10px auto 0;
                    max-width:250px;

                    &:hover {
                        background-color: rgba(0,0,0,0.5);
                    }
                }
            }
        }

        // Location Skimium buttons
        .wrap-rental-skimium {
            position: relative;
            z-index: 2;
            padding:20px;
            background-color: rgba(0,0,0,0.7);

            a {
                padding: 0;
                display: flex;
                align-items: center;
                margin:10px auto 0;
                text-align: left;
                line-height: 1;
                width:270px;

                span {
                    padding-left: 18px;
                    font-weight: 700;
                }
            }
        }
    }

    // Specific for Error 404 page
    .error404 & {
        height: ~"calc(100vh - 20px)";

        .overlay {
            opacity: 0.7;
        }

        .cc-inside {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .wrap-titles-link {
            flex: 1;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            align-content: center;

            h1 {
                flex: 0 0 100%;
                margin: 20px 0;
            }

            p {
                flex: 0 0 100%;
                margin: 10px 0;

                a {
                    display: inline-block;
                    padding: 7px 14px;
                }
            }
        }
    }

    // Specific for Home UNIVERS : (outdoor hiver, ski school, outdoor été, shops...)
    .home-univers & {
        height: 50vh;
        min-height: 360px;

        .cc-inside {
            overflow: hidden;
            height: 100%;
        }

        // UNIVERS TITLES
        .wrap-univers-title {
            position: absolute;
            bottom: 5px;
            right: 20px;
            text-transform: uppercase;
            line-height: 1;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 200px;
            background: linear-gradient(to bottom, rgba(0,0,0,0.65) 30%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
            z-index:1;
        }
    }

    // Specific for details pages : Activity, shop detail, type listing page, ski school detail
    .detail-page & {
        padding-bottom: 30px;
    }

    // Specific for CMS pages
    .cms-page & {
        padding-bottom:40px;

        .cc-inside {
            overflow: hidden;
            height: 100%;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom:0;
            background: rgba(0,0,0,0.65);
            z-index:1;
        }
    }


    // Screen > 359px
    ._media-xs-up({
        // TOP HEADER : logo, langs, menus...
        .wrap-top-header {
            .wrap-contact-infos {
                bottom: 31px;

                a {
                    margin-left: 5px;

                    i {
                        font-size: 2rem;
                    }
                }
            }
        }
    });

    // Screen > 767px
    ._media-s-up({

        // TOP HEADER : logo, langs, menus...
        .wrap-top-header {
            align-items: center;

            .logo {
                margin-right:30px;
            }

            button {
                margin-left: inherit;
            }

            .wrap-contact-infos {
                flex:inherit;
                order:inherit;
                margin-left: auto;
                font-size: 1.5rem;
                position: static;

                a {
                    margin-left: 15px;

                    i {
                        font-size: 2.5rem;
                    }
                }
            }
        }

        // Specific for Homepage
        .homepage & {
            height: 700px;

            .wrap-titles-link {
                padding:0;

                h1 {
                    font-size: 7rem;

                    small {
                        font-size:2.5rem;
                    }
                }

                p {
                    display: flex;
                    justify-content: center;

                    a {
                        font-size: inherit;
                        max-width:220px;
                        margin:0 10px;
                        font-size: inherit;
                        padding:12px;
                    }
                }
            }

            // Location Skimium buttons
            .wrap-rental-skimium {
                position: absolute;
                bottom:0;
                left:0;
                right:0;
                width:100%;

                p {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    max-width:768px;
                    margin: 0 auto;

                    a {
                        margin:10px;
                    }
                }

            }
        }

        // Specific for Error 404 page
        .error404 & {
            .wrap-titles-link {
                p {
                    a {
                        max-width: inherit;
                    }
                }
            }
        }

        // Specific for Home UNIVERS : (outdoor hiver, ski school, outdoor été, shops...)
        .home-univers & {
            // UNIVERS TITLES
            .wrap-univers-title {
                bottom: 5px;
            }
        }
    });


    // Screen > 989px
    ._media-m-up({

        // TOP HEADER : logo, langs, menus...
        .wrap-top-header {
            .wrap-contact-infos {
                flex:inherit;
                order:inherit;
                margin-left: auto;
                font-size: 1.5rem;
                position: static;

                a {
                    margin-left:0px;

                    // Hide Fontawesome icons but not Facebook and Instagram
                    &:not(.external-link) {
                        i {
                            display: none;
                        }
                    }

                    &.external-link {
                        margin-right:10px;
                    }
                }

                span {
                    display: block;
                }
            }
        }

        // Specific for Homepage
        .homepage & {
            height: 750px;

            .wrap-titles-link {
                h1 {

                    small {
                        font-size: 3rem;
                    }
                }

                p {
                    a {
                        padding:12px 22px;
                    }
                }
            }
        }

        // Specific for Home UNIVERS : (outdoor hiver, ski school, outdoor été, shops...)
        .home-univers & {
            // UNIVERS TITLES
            .wrap-univers-title {
                right: 0;
                bottom: 10px;
            }
        }
    });


    // Screen > 1249px
    ._media-l-up({

        // TOP HEADER : logo, langs, menus...
        .wrap-top-header {
            button {
                padding-left: 50px;
                padding-right: 30px;

                i {
                    left: 23px;
                }
            }
        }

        // Specific for Homepage
        .homepage & {
            height: 850px;

            .wrap-titles-link {
                h1 {
                    font-size: 10rem;

                    small {
                        font-size: 4.2rem;
                    }
                }
                p {
                    justify-content: space-between;

                    a {
                        margin:0;
                        flex:0 0 19%;
                    }
                }
            }

            // Location Skimium buttons
            .wrap-rental-skimium {
                h2 {
                    font-size: 4rem;
                }

                p {
                    max-width:inherit;

                    a {
                        margin:0 10px;
                    }
                }
            }
        }

        // Specific for Error 404 page
        .error404 & {
            .wrap-titles-link {
                p {
                    display: block
                }
            }
        }
    });
}

// MAIN NAV
#main-nav {
    position: fixed;
    height: 100vh;
    z-index: 10;
    background-color: @black;
    top: 0;
    right: -100%;
    width: 100%;
    bottom: 0;
    overflow-y: auto;
    ._transition(0.35s, right, cubic-bezier(1,0,0,1));

    & > ul {
        padding: 0 20px;

        & > li {
            text-align: center;
            padding: 20px;
            border-bottom: 1px solid #484848;

            &:nth-child(1) {
                border: 0;
                padding: 0;
            }

            &.double {
                a:nth-child(2) {
                    display: block;
                    font-weight: 300;
                    font-size: 1.6rem;
                }
            }

            & > p,
            & > a {
                display: inline-block;
                padding: 5px 10px;
                font-weight: 600;
                font-size: 2rem;
            }

            // Close button
            button {
                padding: 10px;
                border: 0;
                font-size: 3rem;
            }

            ul {
                li {
                    a {
                        display: inline-block;
                        padding: 3px;
                    }
                }

                // Specific for Activities winter/summer
                &.list-activities {
                    & > li {
                        position: relative;

                        & > a {
                            font-size: 1.8rem;
                            font-weight: 500;
                        }
                        .expand-activities {
                            position: absolute;
                            top: 0;
                            right: 0;
                            padding: 5px;
                        }
                    }

                    ul {
                        display: none;
                    }
                }
            }
        }
    }

    // Screen > 767px
    ._media-s-up({
        width: 50%;

        & > ul {
            position: relative;
            z-index: 2;

            & > li {

                // Close button
                button {
                    font-size: 4rem;
                }
            }
        }

        .overlay {
            position: fixed;
            top: 0;
            left: 0%;
            bottom: 0;
            width: 100%;
            display: none;
            background-color: rgba(0,0,0,0.5);
        }
    });

    // Screen > 989px
    ._media-m-up({

        & > ul > li ul {
            &.list-activities {
                display: flex;
                margin-top: 20px;

                & > li {
                    flex:0 0 50%;

                    .expand-activities {
                        display: none;
                    }

                    ul {
                        display: block !important;
                    }
                }
            }
        }
    });
}

.activity-menu-img {
    display: none;

    ._media-m-up({
        display: block;
        position: fixed;
        height: 100vh;
        z-index: 11;
        background-color: red;
        top: 0;
        right: -35%;
        width: 30%;
        bottom: 0;
        overflow-y: auto;
        opacity:0;
        ._transition(0.3s, opacity);

        &.shown {
            right:50%;
            opacity:1;
        }
    })
}


// Main Footer
#main-footer {
    padding-top: 35px;
    font-size: 1.6rem;

    li ul {
        a {
            font-size: 1.4rem;
            ._transition;

            &:hover {
                color: @primary;
            }
        }

        li > a {
            color: #A5A5A5;
        }
    }

    strong {
        font-weight: 400;
    }

    // Underlined titles
    .footer-title {
        border-bottom: 1px solid #484848;
        padding-bottom: 10px;
        margin-bottom: 20px;
        font-size: 1.8rem;
        font-weight: 400;
    }

    // Small titles : "winter Outdoor", "'Ski school"
    .footer-title-small {
        font-size: 1.6rem;
        font-weight: 400;
    }

    // Left block : A propos, social icons;
    .left {
        .footer-social {
            display: flex;

            a {
                display: block;
                padding:0 10px 10px;
                font-size: 3rem;
            }
        }
    }

    // Sitemap
    .right {
        padding-top:30px;
    }

    // Logos
    .footer-logos {
        a {
            padding:10px;
            display: block;
            text-align: center;

            picture {
                display: inline-block;
            }
        }
    }


    // Screen > 767px
    ._media-s-up({
        // Left block : A propos, social icons;
        .left {
            display: flex;
            justify-content: space-between;

            div {
                &.first {
                    flex:0 0 60%;
                }

                &.second {
                    flex:0 0 35%;
                }
            }
        }

        // Sitemap
        .right {
            padding-top: 60px;

            & > ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                & > li {
                    flex:0 0 30%;

                    &.full-width {
                        flex:0 0 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        padding-bottom: 30px;

                        & > p {
                            flex: 0 0 100%;
                        }

                        .winter-outdoor {
                            flex:0 0 30%;
                        }

                        .ski-school {
                            flex:0 0 65%;

                            & > li {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;

                                & >p {
                                    flex:0 0 100%;
                                }

                                .school-item {
                                    flex:0 0 48%;
                                }
                            }
                        }
                    }
                }
            }
        }

        // Logos
        .footer-logos {
            display: flex;
            flex-wrap: wrap;

            li {
                flex:0 0 24%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    });

    // Screen > 1249px
    ._media-l-up({
        display: flex;
        flex-wrap: wrap;

        .insta {
            width:320px;
            flex:0 0 25%;
            padding:20px 0 0;
            background-color: @black;
            margin-bottom:0;
        }

        & > .cc-inside {
            flex:1;
            margin-left:40px;
            display: flex;
            flex-wrap: wrap;
            padding-top:20px;

            .left {
                display: block;
                flex:0 0 280px;
            }

            .right {
                flex: 1;
                padding-left: 40px;
                padding-top: 0;

            }

            .footer-logos {
                flex: 0 0 100%;
                padding-top: 60px;
            }
        }

        .cc-bg-black {
            flex:0 0 100%;
            color: #737373;

            a {
                color: #737373;

                &:hover {
                    color: @primary
                }
            }
        }
    });
}
