/*
	@@ EVENT PAGE @@
*/
&.event-template {

    &.event-team .wrap-pushes p {
        text-align: left;
    }

    // 3 pushes after header
    .wrap-pushes {
        .item {
            margin-top: 30px;

            &:nth-child(1) {
                margin-top: 0
            }

            a {
                display: block;

                &:hover {
                    h2 {
                        color: @primary;
                    }
                }
            }
        }

        h2 {
            font-weight: 500;
            margin-top: 15px;
            margin-bottom: 15px;
            ._transition;
        }

        p {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }


        // Specific for Activities list
        &.activities-list {

            a {
                display: block;
                max-width:540px;
                margin:0 auto;

                div {
                    position: relative;
                    overflow: hidden;


                    // HOVER ANIMATIONS
                    &:hover {
                        &:before, &:after {
                            background-color: rgba(255,255,255,1);
                        }
                        &:before {
                            width:~"calc(100% - 33px)";
                        }

                        &:after {
                            height:~"calc(100% - 33px)";
                        }
                        .overlay {
                            background-color: rgba(0,0,0,0.7);
                        }
                        h2 {
                            color: white;
                        }
                    }

                    &:after,
                    &:before {
                        content: ' ';
                        display: block;
                        position: absolute;
                        z-index:2;
                        background-color: rgba(255,255,255,0.5);
                        ._transition;
                    }

                    &:before {
                        width:40%;
                        height:1px;
                        top:20px;
                        right:16px;
                    }

                    &:after {
                        width:1px;
                        height:40%;
                        top:16px;
                        right:20px;
                    }

                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom:0;
                        background-color: rgba(0,0,0,0.1);
                        z-index:1;
                        ._transition;
                    }

                    img {
                        display: block;
                        margin:0 auto;
                    }

                    h2 {
                        position: absolute;
                        z-index: 2;
                        bottom: 20px;
                        left: 25px;
                        right: 25px;
                        font-weight: inherit;
                        margin:0;
                        line-height: 1.1;
                        text-shadow:1px 1px 1px rgba(0,0,0,0.6)
                    }
                }
            }

            p {
                margin-top: 15px;
            }
        }
    }

    // Tabs + Tabs content
    .tabs {
        display: flex;

        li {
            flex: auto;

            a {
                border-top: 2px solid transparent;
                display: block;
                text-align: center;
                padding: 10px 20px;

                &:hover,
                &.active {
                    border-top-color: @primary;
                    background-color: @black;
                }
            }
        }

    }

    .wrap-obj-value {
        & > div {
            display: none;
            padding: 20px;

            &.active {
                background-color: @black;
                display: block;
            }
        }
    }

    // Specific for Team listing
    .contain-intro-teams {
        h2 {
            text-align: center;
            margin-bottom: 30px;
        }

        .right {
            margin-top: 30px;

            iframe {
                margin-bottom: 30px;
            }
        }
    }


    // Screen > 767px
    ._media-s-up({

        // 3 pushes after Header
        .wrap-pushes {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .item {
                flex: 0 0 27%;
                margin: 0 3%;

                &.large {
                    flex: 1;
                }
            }
        }

        // Specific for page : Notre equipe terrain
        &.event-team {
            .wrap-pictures .item {
                margin-bottom: 30px;
            }

            .wrap-pushes {
                .item {
                    margin: 3%
                }
            }
        }

        // Specific for Activities list
        &.activities-list {

        }
    });


    // Screen > 989px
    ._media-m-up({
        // Why choose us & Added value
        .wrap-why-choose-added-value {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .why-choose {
                flex: 0 0 48%
            }

            .added-value {
                flex: 0 0 48%;
            }
        }

        // Specific for Team listing
        .contain-intro-teams {
            display: flex;

            h2 {
                text-align: left;
            }

            .left {
                flex: 1;
                padding-right: 40px;

            }

            .right {
                flex: 0 0 350px;
                margin-top: 00px;
            }
        }
    });


    // Screen > 1199px
    ._media-l-up({
        // 3 pushes after Header
        .wrap-pushes {
            justify-content: flex-start;

            &.quarter {
                .item {
                    flex: 0 0 19%
                }
            }
        }
    });
}
