/*
	@@ HOUSING DETAIL PAGE @@
*/
&.housing-page {

	.bg-illu {
		background-image: url('/images/event_elpro_bg-plagne.jpg');
		._cover;
	}

	// Screen > 767px
	._media-s-up({
		#main-header {
			min-height: inherit;
			height: inherit;
		}
	});

	// Screen > 989px
	._media-m-up({
	});
}
