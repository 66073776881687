/*
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
._setHamburger() {
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  ._transition(0.15s, all, ease);
}

// Button
.hamburger {
  padding: 15px;
  display:flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: @primary;
  border: 0;
  margin: 0;
  overflow: visible;
  border-radius: 0px;

  &:hover {
    background-color: @primary;
    border: 0;
  }
}

// button > span
.hamburger-box {
  width: 24px;
  height: 20px;
  display: inline-block;
  position: relative;

  // button > span > span
  .hamburger-inner {
    display: block;
    top: 0px;
    ._setHamburger;

    &::before {
      content: "";
      display: block;
      ._setHamburger;
      width: 100%;
      top: 8px;
      right: 0;
    }

    &::after {
      content: "";
      display: block;
      ._setHamburger;
      width:100%;
      top:16px;
      right: 0;
    }
  }
}

/*
 * Elastic
 */
.hamburger--elastic {
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &:before {
    transition: opacity 0.15s 0.4s ease;
  }

  &:after {
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }


  &.is-active {
    .hamburger-inner {
      transform: translate3d(0, 8px, 0) rotate(135deg);

      &:before {
        transition-delay: 0s;
        opacity: 0;
      }

      &:after {
        width: 100%;
        transform: translate3d(0, -16px, 0) rotate(-270deg);
      }
    }
  }
}