@charset "UTF-8";

// Import normalize
@import 'normalize';

// Import ChuckCSS
@import "../../node_modules/ChuckCSS/chuckcss/front-light";

// Import Fontawesome
@import '../../node_modules/@fortawesome/fontawesome-pro/less/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/light';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/solid';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/brands';
@import '../../node_modules/@fortawesome/fontawesome-pro/less/duotone';

// Override default settings
@import 'settings-override';

// Custom Google Fonts
.worksans() {
    font-family: 'Work Sans', sans-serif;
}

.worksans {
    .worksans;
}


// When main menu is opened
html.opened-menu,
body.opened-menu {
    overflow: hidden;

    // Menu
    #main-nav {
        right: 0;

        .overlay {
            display: block;
        }
    }

    ._media-s-up({
        #main-header,
        #main-content,
        #main-footer {
            ._filter(blur(2px));
            pointer-events: none;
        }
    });

    ._media-m-up({
        padding-right: 17px;
    });
}

// When type menu is opened
html.opened-type-menu,
body.opened-type-menu {
    overflow: hidden;

    // Menu
    #type-menu {
        right: 0;
    }

    ._media-m-up({
        overflow: inherit;
        overflow-x: hidden;
    });
}


body {
    .worksans;
    font-weight: 300;
    background-color: #121212;
    padding: 10px;

    ._media-s-up({
        padding: 15px
    });

    ._media-m-up({
        padding: 20px;
    });


    // Main containers
    .cc-inside {
        ._inside(100%);
        padding: 0 20px;

        // Screen > 989px
        ._media-m-up({
            ._inside(900px);
            padding: 0;
        });

        // Screen > 1189px
        ._media-l-up({
            ._inside(1200px);
        });
    }

    ol, ul {
        ._reset;

        li {
            list-style: none;
        }
    }

    picture img {
        display: block;
    }
}

/* define text selection */
::-moz-selection {
    background: @primary;
    color: white;
}

::selection {
    background: @primary;
    color: white;
}

.h1-like, .h2-like, .h3-like, .h4-like, .h5-like {
    display: block;
}

/* Import Helpers files */
@import 'helpers/hamburger';
@import 'helpers/cookies';
@import 'helpers/callback';

/* Import libraries plugins files */
@import "libraries/fancybox";
@import "libraries/flickity";
@import "libraries/wow";
@import "libraries/tippyjs";

/* Import Website styles */
@import 'website/common';
@import 'website/header-footer';
@import 'website/homepage';
@import 'website/skischool-list';
@import 'website/shops-list';
@import 'website/detail-page';
@import 'website/cms';
/* EVENT */
@import 'website/event/event-app';



