// CMS page : Qui sommes-nous;
.cms-page {

    #main-header {
        position: relative;
    }

    // 3 pushes after header
    .wrap-pushes {
        .item {
            margin-top: 30px;

            &:nth-child(1) {
                margin-top: 0
            }

            a {
                display: block;

                &:hover {
                    h2 {
                        color: @primary;
                    }
                }
            }
        }

        h2 {
            font-weight: 500;
            margin-top: 15px;
            margin-bottom: 15px;
            ._transition;
        }

        p {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            color: @gray;
            font-size: 1.5rem;
            text-align: justify;
        }
    }

	// Screen > 767px
	._media-s-up({

        // 3 pushes after Header
        .wrap-pushes {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .item {
                flex: 0 0 27%;
                margin:3%;

                &.large {
                    flex: 1;
                }

                &:nth-child(1) {
                    margin-top: 3%
                }
            }
        }

	});

	// Screen > 989px
	._media-m-up({

	});

	// Screen > 1250px
	._media-l-up({
        // 3 pushes after Header
        .wrap-pushes {
            justify-content: flex-start;

            &.quarter {
                .item {
                    flex: 0 0 19%
                }
            }
        }
	});
}
