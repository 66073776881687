// SKI SCHOOL HOMEPAGE
.skischool-list {

	// Links only on smartphone
	.phone-links {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		a {
			flex:0 0 100%;
			padding: 7px;
			display: block;
			margin: 10px auto 0;
			max-width:250px;
			background-color: rgba(0,0,0,0.3);

			&:hover {
				background-color: rgba(0,0,0,0.5);
			}
		}
	}

	// 2 Ski School pushes
	.ski-school-item {
		position: relative;
		height: 300px;

		&.item-1 {
			margin-bottom:30px;
		}

		& > a {
			display: block;
			height: 100%;
			position: relative;
			z-index: 2;

			&:after,
			&:before {
				content: ' ';
				display: block;
				position: absolute;
				background-color: white;
				._transition;
			}

			&:before {
				width:40%;
				height:1px;
				top:20px;
				right:16px;
			}

			&:after {
				width:1px;
				height:40%;
				top:16px;
				right:20px;
			}
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom:0;
			background-color: rgba(0,0,0,0.5);
			z-index:1;
			._transition;
		}

		.wrap-skischool-infos {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		strong {
			text-transform: uppercase;
			font-weight: 700;
		}
	}


	// Screen > 359px
	._media-xs-up({
		
	});

	// Screen > 767px
	._media-s-up({
		// Hide skischool Links displayed only on smartphone
		.phone-links {
			display: none;
		}

		.wrap-skischools {
			display: flex;
			justify-content: space-between;
		}
		
		// 2 Ski School pushes
		.ski-school-item {
			flex:1;
			margin-left:20px;

			&.item-1 {
				margin-bottom:0;
				margin-right:20px;
				margin-left:0px;
			}

			h2 {
				font-size: 3rem;
			}

			// HOVER ANIMATIONS
			&:hover {
				& > a {
					&:before {
						width:~"calc(100% - 33px)";
					}

					&:after {
						height:~"calc(100% - 33px)";
					}
				}
				.overlay {
					background-color: rgba(0,0,0,0.7);
				}
			}
		}
	});

	// Screen > 989px
	._media-m-up({
	});

	// Screen > 1250px
	._media-l-up({

		// 2 Ski School pushes
		.ski-school-item {
			height:380px;

			h2 {
				font-size: 3.6rem;
			}
		}
	});
}