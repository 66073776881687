/*
	@@ COMMON STYLES FOR ALL EVENT PAGES @@
*/

// Citation in member detail page
blockquote.citation {
	font-style: normal;
	font-size:120%
}

// Gray texts
.gray-text {
	color: @gray;
	font-size: 1.5rem;
	text-align: justify;
}

// CTA
.cta {
	border-left:3px solid @primary;
	padding:20px;

	// Screen > 989px
	._media-m-up({
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding:30px 20px;
	});
}

// Common Headers
#main-header {
	position: relative;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: black;
		opacity: 0.4;
		z-index:1;
	}

	// Boutons Métiers
	.wrap-titles-link {
		position: relative;
		z-index: 2;
		padding:0px 0px 50px;

		.h1-like,
		h1 {
			font-weight: 600;
			font-size: 5rem;

			small {
				display: block;
				font-size: 2rem;
			}
		}
		.h1-like {
			font-size: 3.5rem;
		}
		p {
			a {
				padding: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 10px auto 0;
				max-width:250px;

				&:hover {
					background-color: rgba(0,0,0,0.5);
				}
			}
		}
	}

	// Screen > 767px
	._media-s-up({
		min-height: 50vh;
		height: 50vh;

		.wrap-titles-link {
			padding:0;

			.h1-like,
			h1 {
				font-size: 7rem;

				small {
					font-size:2.5rem;
				}
			}

			p {
				display: flex;
				justify-content: center;

				a {
					font-size: inherit;
					max-width:220px;
					margin:0 10px;
					font-size: inherit;
					padding:12px;
				}
			}
		}
	});

	// Screen > 989px
	._media-m-up({
		.wrap-titles-link {
			.h1-like,
			h1 {

				small {
					font-size: 3rem;
				}
			}

			p {
				a {
					padding:12px 22px;
				}
			}
		}
	});
}
&.homepage #main-header {
    height:inherit;
    min-height: inherit;

    .wrap-titles-link {
        padding-bottom:50px;
    }
}

// Carousel incentives activities
.wrap-incentives-activities {
	padding-bottom: 0;

	.main-carousel {
		opacity: 0;
		._transition(0.4s, opacity);

		&.shown {
			opacity: 1;
		}
	}
}

// Activity push in listings
.event-activity-item {

	// Not carousel cell
	&:not(.carousel-cell) {
		margin-bottom:30px;
	}

	a {
		display: block;
        position: relative;

        // HOVER ANIMATIONS
        &:hover {
            &:before, &:after {
                background-color: rgba(255,255,255,1);
            }
            &:before {
                width:~"calc(100% - 33px)";
            }

            &:after {
                height:~"calc(100% - 33px)";
            }
            .overlay {
                background-color: rgba(0,0,0,0.7);
            }
            h3 {

            }
        }

        &:after,
        &:before {
            content: ' ';
            display: block;
            position: absolute;
            z-index:2;
            background-color: rgba(255,255,255,0.5);
            ._transition;
        }

        &:before {
            width:40%;
            height:1px;
            top:20px;
            right:16px;
        }

        &:after {
            width:1px;
            height:40%;
            top:16px;
            right:20px;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom:0;
            background-color: rgba(0,0,0,0.1);
            z-index:1;
            ._transition;
        }


		p {
			overflow: hidden;

			img {
				display: block;
			}
		}

		h3 {
			position: absolute;
            z-index: 2;
            bottom: 20px;
            left: 25px;
            right: 25px;
            line-height: 1.1;
            text-shadow:1px 1px 1px rgba(0,0,0,0.6)
		}

		// &:hover {
		// 	p {
		// 		img {
		// 			opacity: 1
		// 		}
		// 	}
		// 	h3 {
		// 		color: @primary;
		// 	}
		// }
	}

	&.carousel-cell {
		width:280px;
		margin:0 20px;
	}


	@media screen and (min-width:450px) {
		// Not carousel cell
		&:not(.carousel-cell) {
			flex:0  46.0%;
			margin:0 2.0% 30px;
		}
	}

	// Screen > 767px
	._media-s-up({
		// Not carousel cell
		&:not(.carousel-cell) {
			flex:0  29.0%;
		}
	});

	// Screen > 989
	._media-m-up({
		&.small {
			flex:0  21.0%;
		}
	});

	// Screen > 1199px
	._media-l-up({
		// Not carousel cell
		&:not(.carousel-cell) {
			flex:0  21.0%;
		}
		&.large {
			flex:0  29.0%;
		}
		&.small {
			flex:0  16.0%;
		}
	});
}

// Logos "Ils nous ont fait confiance"
.wrap-customers-logos {

	.flickity-slider {
		display: flex;
		align-items: center;
	}

	/* disable Flickity for large devices */
	.carousel {
		.carousel-cell {
			width:150px;
			margin:0 30px;
		}
	}
}

// Text zone with floating left image
.txt-zone {

	.contain-carousel {

		// Single image
		& > a,
		& > img {
			display: block;
			width: 100%;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
		}

		// Carousel
		.carousel img {
			display: block;
			height: 200px;
			margin: 0 10px;
		}
	}



	// Screen > 767px
	._media-s-up({
		display: flex;

		&.img-right {
			.contain-carousel {
				order: 2;
				margin-left: 30px;
				margin-right: 0px;
			}
		}

		.contain-carousel {
			flex: 0 0 300px;
			margin-right: 30px;

            a, img {
                display: block;
                width: 100%;
                max-width: 500px;
                margin-left: auto;
                margin-right: auto;
            }
		}
	});

	// Screen > 989px
	._media-m-up({

	});


	// Screen > 1199px
	._media-l-up({
		.contain-carousel {
			flex:0 0 540px;

			.carousel img {
				height:360px;
			}
		}

		& > p {
			flex:0 0 500px;
		}
	});
}

// FOOTER Logos
.logos {
	h4 {
		text-align: center;
		padding-bottom:20px;
		font-weight: 500;
	}

	// Logos "Ils nous ont fait confiance"
	.wrap-customers-logos {

		.flickity-slider {
			display: flex;
			align-items: center;
		}

		/* disable Flickity for large devices */
		.carousel {
			.carousel-cell {
				width:150px;
				margin:0 30px;
			}
		}
	}

	._media-l-up({
		h4 {
			font-size: 2.4rem;
		}
	});
}

#main-footer {
	padding-top:0;
}
